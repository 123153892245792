import { Box } from '@mui/material';
import getUrlParameter from 'utils/getUrlParameter';
import { capitalizeString, isEmpty } from 'utils/helpers';
import dynamicTitles from './dynamicTitles';

const DynamicTitle = ({ state }) => {
  const dt = getUrlParameter('sub5') as any;
  //const dynamicTitle = isEmpty(dt) ? undefined : dynamicTitles.find(t => t?.toLowerCase() === dt?.toLowerCase());
  //const defaultTitle = `Affordable Healthcare Plans${`${isEmpty(state) ? '' : ` in ${state}`}`}`;
  const title = `Find the lowest car insurance rates for your vehicle`;

  return (
    <Box sx={{ textAlign: 'center', fontSize: 26, fontWeight: 700, lineHeight: 1.2, px: 1 }}>
      {/*dynamicTitle ? capitalizeString(dynamicTitle) : defaultTitle*/}
      {title}
    </Box>
  );
};

export default DynamicTitle;
