import React from 'react';
import { Link, Button, Box, Grid, Typography, useMediaQuery, Divider } from '@mui/material';
import ArrowForward from '@mui/icons-material/ArrowForward';
import useGeoData from 'utils/useGeoData';
import { isClient, isEmpty } from 'utils/helpers';
import { ConnectFormField } from 'components/layout/ReactFormProvider';
import * as Icons from '@mui/icons-material';
import { green, grey } from '@mui/material/colors';
import GeoZipWhite from 'components/application/Fields/GeoZipWhite';
import useChangeRoute from 'utils/useChangeRoute';
import DynamicTitle from 'components/layout/DynamicTitle';
import { useCurrentPhone } from 'store/layout/store';
import Image from 'next/image';
import { useFormContext } from 'react-hook-form';
import useUrlParameterFields from 'components/application/Fields/useUrlParameterFields';
import getUrlParameter from 'utils/getUrlParameter';

const providers = [
  'NationwideLogo.webp',
  'EsuranceLogo.webp',
  'AAAInsuranceLogo.webp',
  'FarmersInsuranceLogo.webp',
  'LibertyMutualLogo.webp',
  'ProgressiveLogo.webp',
  'AmericanFamilyInsuranceLogo.webp'
];

const advantages = [
  {
    path: 'unbiased-rankings.svg',
    title: 'Unbiased rankings',
    description:
      'Unlike other sites, we don’t rank companies based on how much they pay us to refer customers. We rank them solely on the basis of how highly customers rank them in regards to price and overall buying experience.'
  },
  {
    path: 'top-ranked.svg',
    title: 'Get quotes from top ranked insurance providers',
    description: 'Choose from top customer-rated insurance carriers companies such as Allstate, Progressive, Liberty Mutual, and other top-rated companies.'
  },
  {
    path: 'data-privacy.svg',
    title: 'We protect your data & privacy',
    description:
      'We do not collect contact information before we display rankings. We do not share your data with any third parties. You will not get annoying calls, text, or emails. You are in control.'
  }
];

export const Providers = ({ borderBottomLeftRadius = undefined, borderBottomRightRadius = undefined }) => {
  const fontSize = 14;
  const currentPhone = useCurrentPhone();
  return (
    <>
      <Grid
        container
        spacing={1}
        sx={{ fontSize, pt: 3, pb: 6, px: 2, background: grey[100], borderRadius: 0, borderBottomLeftRadius, borderBottomRightRadius }}
        alignItems='center'
        justifyContent={'center'}
      >
        <Grid item xs={12} sx={{}}>
          <Typography sx={{ fontSize: 20, fontWeight: 600, textAlign: 'center', color: 'text.secondary' }} component='div'>
            Would you rather get your quote over the phone? Call
            <Link href={`tel:${currentPhone?.replace(/-|\s/g, '')}`} sx={{ textDecoration: 'none' }}>
              <Typography
                sx={{
                  fontSize: 20,
                  fontWeight: 600,
                  textAlign: 'left',
                  //   color: 'text.secondary',
                  cursor: 'pointer',
                  color: 'blue, display:inline',
                  display: 'inline',
                  textDecoration: 'underline',
                  px: 0.5
                }}
                component='div'
              >
                {currentPhone}
              </Typography>
            </Link>
            for assistance.
          </Typography>
          <Divider sx={{ mt: 3 }} />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={0.5} justifyContent='center' alignItems='center' sx={{ mt: 0 }}>
            <Grid item sx={{ textAlign: 'center' }}>
              <Grid container alignItems='center' spacing={0.5}>
                <Grid item>
                  <Typography sx={{ color: 'text.secondary', fontSize: 20, fontWeight: 700 }}>{`Providers Include:`}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={4} justifyContent='center' alignItems='center' sx={{ mt: 1 }}>
            {providers.map((p, i) => (
              <Grid item key={i}>
                <Image src={`/${p}`} width={120} height={60} alt={`provider-${i}`} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export const Advantages = ({ borderBottomLeftRadius = undefined, borderBottomRightRadius = undefined }) => {
  const fontSize = 14;
  return (
    <>
      <Grid
        container
        spacing={1}
        sx={{ fontSize, pb: 1.5, px: 2, background: grey[100], borderRadius: 0, borderBottomLeftRadius, borderBottomRightRadius }}
        alignItems='center'
        justifyContent={'center'}
      >
        <Grid item xs={12}>
          <Divider sx={{ mt: 0 }} />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={0.5} justifyContent='center' alignItems='center' sx={{ mt: 1 }}>
            <Grid item sx={{ textAlign: 'center' }}>
              <Grid container alignItems='center' spacing={0.5}>
                <Grid item>
                  <Typography sx={{ color: 'text.secondary', fontSize: 20, fontWeight: 700 }}>{`Advantages Include:`}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={6} alignItems='flex-start' justifyContent='center' sx={{ mt: 0, px: 4 }}>
            {advantages.map(({ path, title, description }, i) => (
              <Grid item key={i} xs={12} sm={6} md={4}>
                <Grid container direction='column' spacing={1} justifyContent='flex-start'>
                  <Grid item sx={{ textAlign: 'center' }}>
                    <Image src={`/${path}`} width={150} height={150} alt={`provider-${i}`} />
                  </Grid>
                  <Grid item sx={{ fontWeight: 700, fontSize: 20, textAlign: 'center' }}>
                    {title}
                  </Grid>
                  <Grid item sx={{ fontSize: 14, textAlign: 'center', color: 'text.secondary' }}>
                    {description}
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default function Home({ version = 0 }) {
  const changeRoute = useChangeRoute();
  const { state } = useGeoData({ trigger: false });
  const sm = useMediaQuery('(max-width:750px)');
  const xs = useMediaQuery('(max-width:400px)');
  const isMobile = useMediaQuery('(max-width:500px)');

  const transaction_ID = !isClient ? undefined : (window as any)?.EF?.getAdvertiserTransactionId(1);
  const up_transaction_ID = getUrlParameter('transaction_ID');

  var shouldSet = isEmpty(transaction_ID) ? false : isEmpty(up_transaction_ID) ? true : Number(up_transaction_ID) !== Number(transaction_ID) ? true : false;

  console.log({ shouldSet, transaction_ID, up_transaction_ID });

  React.useEffect(() => {
    console.log({ transaction_ID });
    if (shouldSet && !isEmpty(transaction_ID)) {
      changeRoute(`/${version > 0 ? `v${version}` : ''}?transaction_ID=${transaction_ID}`);
      //changeRoute(`/?transaction_ID=${transaction_ID}`);
    }
    // eslint-disable-next-line
  }, [transaction_ID, shouldSet]);

  const { watch } = useFormContext();
  const zip = watch('zipcode');
  const handleComparePlans = React.useCallback(() => {
    if (isEmpty(zip)) {
      alert('Must enter your zip code!');
    } else {
      const transaction_ID = !isClient ? undefined : (window as any)?.EF?.getAdvertiserTransactionId(1);
      console.log({ transaction_ID });
      if (!isEmpty(transaction_ID)) {
        changeRoute(`/Questions${version > 0 ? version : ''}?transaction_ID=${transaction_ID}`, { backRoute: '' });
      } else {
        changeRoute(`/Questions${version > 0 ? version : ''}`, { backRoute: '' });
      }
    }
  }, [changeRoute, version, zip]);

  const gap = useMediaQuery('(max-width:625px)');

  return (
    <>
      <Box sx={{ backgroundColor: 'primary.main', color: 'white', py: 4, textAlign: 'center' }}>
        <DynamicTitle state={state} />
        <Box sx={{ textAlign: 'center', fontSize: 17, fontWeight: 500, pt: 2, pb: gap ? 3 : 0, px: 1 }}>{`PLANS STARTING AT $19/MONTH`}</Box>
        {/*<Box sx={{ textAlign: 'center', fontSize: 17, fontWeight: 500, pt: 2, pb: gap ? 3 : 0, px: 1 }}>{`ENTER ZIP CODE TO BEGIN`}</Box>*/}
        <Grid container justifyContent='center' alignItems='center'>
          <Grid item sx={{ width: 272, backgroundColor: 'white', borderRadius: 1 }}>
            <ConnectFormField id={'zipcode'} Field={GeoZipWhite} label='Zip Code' placeholder='Enter Zip Code' />
          </Grid>
          <Grid item sx={{ mx: 2 }}>
            <Button
              onClick={handleComparePlans}
              sx={{
                '&:hover': { backgroundColor: green[800] },
                backgroundColor: green[700],
                color: 'white',
                my: 3,
                height: 64,
                width: 272,
                fontSize: 23
              }}
              size='large'
              variant='contained'
            >
              Check Rates
              <ArrowForward sx={{ ml: 1 }} />
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Grid container spacing={3} alignItems='center' sx={{ backgroundColor: 'primary.dark', color: 'white', justifyContent: 'center', spacing: 4, pb: 2 }}>
        <Grid item>
          <Grid container spacing={1} sx={{ cursor: 'pointer' }} onClick={handleComparePlans}>
            <Grid item>
              <Icons.AccessTime />
            </Grid>
            <Grid item>Quick & Easy</Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container spacing={1} sx={{ cursor: 'pointer' }} onClick={handleComparePlans}>
            <Grid item>
              <Icons.Star />
            </Grid>
            <Grid item>Top Rated Partners</Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container spacing={1} sx={{ cursor: 'pointer' }} onClick={handleComparePlans}>
            <Grid item>
              <Icons.Shield />
            </Grid>
            <Grid item>100% Safe & Secure</Grid>
          </Grid>
        </Grid>
      </Grid>
      <Providers />
      <Advantages />
      <Box sx={{ px: 2 }}>
        <Divider sx={{ my: 3 }} />
        <Box sx={{ textAlign: 'center', fontSize: 26, fontWeight: 700, lineHeight: 1.2, px: 1 }}>{`Save Money by Comparing Insurance Quotes`}</Box>
        <Box
          sx={{ textAlign: 'center', fontSize: 17, fontWeight: 500, pt: 2, pb: gap ? 3 : 0, px: 1, color: 'text.secondary' }}
        >{`Compare Free Insurance Quotes Instantly`}</Box>
        <Grid container justifyContent='center' alignItems='center'>
          <Grid item sx={{ width: 272, backgroundColor: 'white', borderRadius: 1 }}>
            <ConnectFormField id={'zipcode'} Field={GeoZipWhite} label='Zip Code' placeholder='Enter Zip Code' />
          </Grid>
          <Grid item sx={{ mx: 2 }}>
            <Button
              onClick={handleComparePlans}
              sx={{
                '&:hover': { backgroundColor: green[800] },
                backgroundColor: green[700],
                color: 'white',
                my: 3,
                height: 64,
                width: 272,
                fontSize: 23
              }}
              size='large'
              variant='contained'
            >
              Check Rates
              <ArrowForward sx={{ ml: 1 }} />
            </Button>
          </Grid>
        </Grid>

        <Box
          sx={{ textAlign: 'center', fontSize: 15, fontWeight: 500, pt: 2, pb: gap ? 3 : 0, px: 1, color: 'text.secondary' }}
        >{`Secured with SHA-256 Encryption`}</Box>
      </Box>
    </>
  );
}
